import React from 'react';
import classnames from 'classnames';
import {RichTypography} from 'components/RichTypography';

import styles from './Steps.module.scss';
import {upperFirst} from '../../../../services';
import {useLocale} from 'hooks';
import {LocalizedText} from 'components/LocalizedText';

interface StepsProps {
  method: ToolboxObject.MethodDetails;
}

const orderSteps = (stepsList: ToolboxObject.Step[]) => {
  return [...stepsList].sort((a, b) => {
    return a.order - b.order;
  });
};

export const Steps: React.FC<StepsProps> = ({method}) => {
  const locale = useLocale();
  return (
    <div className={styles.steps}>
      {method.files?.main_steps && (
        <div>
          <h2 className={classnames(styles.heading, 'mb-8')}>
            <LocalizedText id="pages.library.method.mainSteps" />
          </h2>
          {method.files.main_steps.map((file) => (
            <img src={file.link} key={file.id} alt={file.display_name} />
          ))}
        </div>
      )}
      <div className={styles.wrapper}>
        {method.steps.preparation && (
          <div className={styles.card}>
            <h3 className={styles.heading}>
              <LocalizedText id="pages.library.method.preparation" />
            </h3>
            <ul>
              {orderSteps(method.steps.preparation).map((step) => {
                const preparationText = step.translation?.[locale] || step.description;

                return (
                  <li key={step.id}>
                    <span className={styles.number}>{step.order}</span>
                    <RichTypography className={styles.text} text={upperFirst(preparationText)} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {method.steps.execution && (
          <div className={styles.card}>
            <h3 className={styles.heading}>
              <LocalizedText id="pages.library.method.conductingMethod" />{' '}
            </h3>
            <ul>
              {orderSteps(method.steps.execution).map((step) => {
                const executionText = step.translation?.[locale] || step.description;
                return (
                  <li key={step.id}>
                    <span className={styles.number}>{step.order}</span>
                    <RichTypography className={styles.text} text={upperFirst(executionText)} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
