export const libraryTabs = [
  {id: 'recipes', titleKey: 'pages.library.recipesTab'},
  {id: 'methods', titleKey: 'pages.library.methodsTab'},
  {id: 'favorites', titleKey: 'pages.library.favoritesTab'},
];
export const adminLibraryTabs = [
  {
    id: 'methods',
    titleKey: 'pages.admin.methodsTab',
  },
  {
    id: 'draft methods',
    titleKey: 'pages.admin.draftMethodsTab',
  },
];

export const adminTranslationTabs = [
  {
    id: 'general',
    title: 'General',
  },
  {
    id: 'methods',
    title: 'Methods',
  },
];

export const adminMethodTranslationTabs = [
  {
    id: 'general',
    title: 'General',
  },
  {
    id: 'tips',
    title: 'Tips',
  },
  {
    id: 'requirements',
    title: 'Requirements',
  },
  {
    id: 'results',
    title: 'Results',
  },
  {
    id: 'benefits',
    title: 'Benefits',
  },
  {
    id: 'preparations',
    title: 'Preparations',
  },
  {
    id: 'executions',
    title: 'Executions',
  },
  {
    id: 'materials',
    title: 'Materials',
  },
];
