import React from 'react';
import {Card, LocalizedText, RichTypography} from 'components';
import {Flag, Puzzle, Fire} from 'assets';

import styles from './DescriptionCards.module.scss';
import {upperFirst} from '../../../../services';
import {useLocale} from 'hooks';

interface DescriptionCardsProps {
  method: ToolboxObject.MethodDetails;
}

interface DescriptionCardProps {
  infos?: ToolboxObject.Info[];
  color?: string;
  titleKey: string;
  icon: JSX.Element;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({icon, infos, color, titleKey}) => {
  const locale = useLocale();
  return (
    <Card headerIcon={icon} headerTitleKey={titleKey} color={color}>
      <ul>
        {infos?.length ? (
          infos.map((info) => {
            const infoTranslation = info.translation?.[locale] || info.description;
            return (
              <li key={info.id}>
                <RichTypography text={upperFirst(infoTranslation)} />
              </li>
            );
          })
        ) : (
          <li>
            <LocalizedText id="pages.library.method.comingSoon" />
          </li>
        )}
      </ul>
    </Card>
  );
};

export const DescriptionCards: React.FC<DescriptionCardsProps> = ({method}) => {
  const color = method.subcategory?.category.color;
  const descriptionsCards = [
    {
      titleKey: 'pages.library.method.requirements',
      icon: <Puzzle />,
      data: method.infos?.requirement,
    },
    {
      titleKey: 'pages.library.method.results',
      icon: <Flag />,
      data: method?.infos?.result,
    },
    {
      titleKey: 'pages.library.method.benefits',
      icon: <Fire />,
      data: method?.infos?.benefit,
    },
  ];
  return (
    <div className={styles.descriptionCards}>
      <div className={styles.wrapper}>
        {descriptionsCards.slice(0, 2).map((card) => (
          <DescriptionCard infos={card.data} titleKey={card.titleKey} key={card.titleKey} color={color} icon={card.icon} />
        ))}
      </div>
      {descriptionsCards.slice(2).map((card) => (
        <DescriptionCard infos={card.data} titleKey={card.titleKey} key={card.titleKey} color={color} icon={card.icon} />
      ))}
    </div>
  );
};
