import React from 'react';
import {Button, Form} from 'antd';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {EditorFormItem, TextEditor} from 'components';
import styles from '../../AdminPage.module.scss';

interface DynamicListProps {
  name: string;
  title: string;
}

export const DynamicList: React.FC<DynamicListProps> = ({name, title}) => {
  return (
    <Form.List name={name}>
      {(fields, {add, remove}, {errors}) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item key={field.key} label={index === 0 ? title : ''}>
                  <EditorFormItem
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Field is required',
                      },
                    ]}
                    noStyle
                    initialValue=""
                    name={[field.name, 'description']}
                  >
                    <TextEditor autoFocus={false} />
                  </EditorFormItem>
                  {fields.length > 1 ? <MinusCircleOutlined className={styles.dynamicDeleteButton} onClick={() => remove(field.name)} /> : null}
                </Form.Item>
              );
            })}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} style={{width: '80%'}} icon={<PlusOutlined />}>
                Add new
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};
