import {useLocale} from './useLocale';

export const translateMethod = <T extends {translations: ToolboxObject.MethodTranslation[]}>(locale: ToolboxObject.Locale, method: T) => {
  const translations: Record<string, any> = {};
  method.translations.forEach((t) => {
    const {method_key, translation} = t;
    if (method_key in method && translation[locale]) {
      translations[method_key] = translation[locale];
    }
  });

  return {...method, ...translations} as T;
};

export const useTranslateMethod = <T extends ToolboxObject.Method>(method: T) => {
  const locale = useLocale();

  return translateMethod(locale, method);
};
