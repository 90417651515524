import React from 'react';
import {SearchInput, Button} from 'components';
import {CreateTranslationModal, TranslationTable} from './components';
import {NavTabs} from 'pages/LibraryPage/NavTabs';
import {MethodLibrary} from 'pages/LibraryPage/MethodLibrary';
import {RoutePath} from 'Router';
import {useLocalizedNavigation} from 'hooks';
import {adminTranslationTabs} from 'constants/tabs.constants';

interface AdminTranslationPageProps {
  defaultTab?: string;
}

export const AdminTranslationPage: React.FC<AdminTranslationPageProps> = ({defaultTab = 'static'}) => {
  const [search, setSearch] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<string>(defaultTab);

  const navigate = useLocalizedNavigation();
  const handleMethodClick = React.useCallback((id: number) => {
    navigate(`${RoutePath.adminTranslation}/methods/${id}`);
  }, []);

  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-4 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">Translations</p>

        <div className="w-full border-black/[0.6] border-b mb-8">
          <NavTabs tabs={adminTranslationTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        {activeTab === 'general' && (
          <>
            <div className="flex justify-between items-center">
              <SearchInput query={search} setQuery={setSearch} />
              <Button onClick={() => setIsOpen(true)}>+ Create New</Button>
            </div>
            <TranslationTable search={search} />
          </>
        )}
        {activeTab === 'methods' && <MethodLibrary handleMethodClick={handleMethodClick} showOthers />}
      </div>
      <CreateTranslationModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
