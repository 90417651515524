import React, {useMemo, useState} from 'react';
import {Disclosure} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDisconnectMiro, useSelf} from 'services/query';
import {useQueryClient} from '@tanstack/react-query';
import {LocalizedNavLink, LocalizedText, Menu} from 'components';
import {UserRole} from 'types';
import {RoutePath} from '../../Router';
import {logout} from '../../services';
import {Mokojam, MokojamLogo} from '../../assets';
import {InviteModal} from '../../components/InviteModal';
import {GlobalOutlined} from '@ant-design/icons';
import {SUPPORTED_LANGUAGES} from 'constants/app.constants';
import {useIntl} from 'react-intl';
import {useLocale, useLocalizedNavigation} from 'hooks';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const HeaderComponent: React.FC = () => {
  const intl = useIntl();
  const locale = useLocale();
  const location = useLocation();

  const {data: self} = useSelf();
  const {mutate: disconnectMiro} = useDisconnectMiro();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const navigation = [
    {titleKey: 'pages.home.pageTitle', href: RoutePath.root},
    {titleKey: 'pages.library.pageTitle', href: RoutePath.library},
    {titleKey: 'pages.workshop.pageTitle', href: RoutePath.workshopLibrary},
    ...(self?.role === UserRole.ADMIN
      ? [
          {titleKey: 'pages.adminDashboard.pageTitle', href: RoutePath.adminDashboard},
          {titleKey: 'pages.adminTranslations.pageTitle', href: RoutePath.adminTranslation},
        ]
      : []),
  ];
  const localizedNavigate = useLocalizedNavigation();
  const navigate = useNavigate();
  const qc = useQueryClient();

  const menuItems = useMemo(() => {
    return [
      ...(self?.miroAccess
        ? [
            {
              title: intl.formatMessage({id: 'header.menu.disconnectMiro'}),
              onClick: disconnectMiro,
            },
          ]
        : []),
      ...(self?.role === UserRole.ADMIN
        ? [
            {
              title: intl.formatMessage({id: 'header.menu.inviteUser'}),
              onClick: () => {
                setShowInviteModal(true);
              },
            },
          ]
        : []),
      {
        title: intl.formatMessage({id: 'header.menu.signOut'}),
        onClick: async () => {
          try {
            await logout();
          } catch (e: any) {
            alert('Something went wrong, you have been logged out');
          } finally {
            localStorage.removeItem('userSession');
            qc.clear();
            await qc.invalidateQueries();
            localizedNavigate(RoutePath.login);
          }
        },
      },
    ];
  }, [qc, logout, navigate, self, locale]);

  const languageItems = SUPPORTED_LANGUAGES.map((lang) => ({
    title: intl.formatMessage({id: `locale.${lang}`}),
    onClick: () => {
      const currentPath = location.pathname.slice(4);
      navigate(`/${lang}/${currentPath}`);
    },
  }));
  return (
    <>
      <InviteModal
        isOpen={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
        }}
      />
      <Disclosure as="nav" className="bg-black font-['Inter']">
        {({open}) => {
          return (
            <>
              <div className="mx-auto px-2 md:px-6">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      id="create-workshop-button-mobile"
                      className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? <XMarkIcon className="block h-6 w-6" /> : <Bars3Icon className="block h-6 w-6" />}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <LocalizedNavLink to={RoutePath.root}>
                        {({isActive}) => {
                          return (
                            <div className="flex items-center block h-8 w-auto gap-2 cursor-pointer">
                              <MokojamLogo className="h-[1.5em] md:hidden" iswhite={isActive ? 1 : 0} />
                              <Mokojam className="h-[1.5em] hidden md:block" iswhite={isActive ? 1 : 0} />
                            </div>
                          );
                        }}
                      </LocalizedNavLink>
                    </div>
                    <div className="hidden md:ml-6 md:block">
                      <div className="flex space-x-4">
                        {navigation.map((item) => {
                          return (
                            <LocalizedNavLink
                              key={item.titleKey}
                              className={({isActive}) =>
                                classNames(
                                  isActive ? 'bg-white' : 'text-white text-opacity-80 hover:bg-white hover:bg-opacity-40 hover:text-white cursor-pointer',
                                  'px-3 py-2 rounded-md text-sm font-medium'
                                )
                              }
                              to={item.href}
                            >
                              <LocalizedText id={item.titleKey} />
                            </LocalizedNavLink>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                    {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                    {/* Profile dropdown */}
                    {self && (
                      <LocalizedNavLink to="/editor/new">
                        {({isActive}) =>
                          isActive ? null : (
                            <div
                              id="create-workshop-button"
                              className="hidden md:block min-w-[141px] hover:cursor-pointer ml-auto hidden text-homeblue border border-solid border-homeblue bg-black hover:bg-homeblue hover:bg-opacity-20 px-3 py-2 rounded-md text-sm font-medium"
                            >
                              <LocalizedText id="header.menu.newWorkshop" />
                            </div>
                          )
                        }
                      </LocalizedNavLink>
                    )}
                    <Menu
                      className="ml-3"
                      leftAligned={false}
                      triggerElement={
                        <div className="flex justify-center rounded-full text-sm h-8 w-8 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <GlobalOutlined style={{color: 'white', fontSize: 24}} />
                        </div>
                      }
                      items={languageItems}
                    />
                    <Menu
                      className="ml-3"
                      leftAligned={false}
                      triggerElement={
                        <div className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <div className="flex items-center justify-center h-8 w-8 rounded-full bg-white bg-opacity-40">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              style={{height: '32px', width: '32px'}}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      }
                      items={menuItems}
                    />
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="md:hidden">
                <div className="flex flex-col px-2 pt-2 gap-3 pb-3">
                  <div className="pt-2 gap-2">
                    {navigation.map((item) => {
                      return (
                        <LocalizedNavLink
                          to={item.href}
                          key={item.titleKey}
                          className={({isActive}) =>
                            classNames(
                              isActive ? 'bg-white' : 'text-white text-opacity-80 hover:bg-white hover:bg-opacity-40 hover:text-white',
                              'block px-3 py-2 rounded-md text-base font-medium'
                            )
                          }
                        >
                          {(isActive) => {
                            return (
                              <Disclosure.Button aria-current={isActive ? 'page' : undefined}>
                                <LocalizedText id={item.titleKey} />
                              </Disclosure.Button>
                            );
                          }}
                        </LocalizedNavLink>
                      );
                    })}
                  </div>
                  <LocalizedNavLink to="/editor/new">
                    <Disclosure.Button
                      className="text-homeblue border border-solid hover:bg-homeblue hover:bg-opacity-40 block px-3 py-2 rounded-md text-base font-medium"
                      aria-current={undefined}
                    >
                      <LocalizedText id="header.menu.newWorkshop" />
                    </Disclosure.Button>
                  </LocalizedNavLink>
                </div>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </>
  );
};

export const Header = React.memo(HeaderComponent);
