import {Loader} from 'components';
import {adminMethodTranslationTabs} from 'constants/tabs.constants';
import {NavTabs} from 'pages/LibraryPage/NavTabs';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useMethod} from 'services/query';
import {GeneralMethodContent, MethodInfoItemList, MethodMaterialItemList, MethodStepItemList} from './components';

interface AdminMethodTranslationPageProps {
  defaultTab?: string;
}

export const AdminMethodTranslationPage: React.FC<AdminMethodTranslationPageProps> = ({defaultTab = 'plain'}) => {
  const {id: methodId} = useParams();
  const {data: method, isLoading} = useMethod(+methodId!, {
    enabled: !!methodId,
  });
  const [activeTab, setActiveTab] = React.useState<string>(defaultTab);
  return (
    <div className="flex flex-col px-2 lg:px-24 py-12 top-16 gap-4">
      <p className="text-2xl font-extrabold text-gray-900 mb-8">Method Translations</p>
      <NavTabs tabs={adminMethodTranslationTabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      {isLoading || !method ? (
        <Loader />
      ) : (
        <>
          {activeTab === 'general' && <GeneralMethodContent method={method} />}

          {activeTab === 'tips' && <MethodInfoItemList list={method.infos?.tip || []} title="tip" methodId={method.id} />}
          {activeTab === 'requirements' && <MethodInfoItemList list={method.infos?.requirement || []} title="requirement" methodId={method.id} />}
          {activeTab === 'results' && <MethodInfoItemList list={method.infos?.result || []} title="result" methodId={method.id} />}
          {activeTab === 'benefits' && <MethodInfoItemList list={method.infos?.benefit || []} title="benefit" methodId={method.id} />}

          {activeTab === 'preparations' && <MethodStepItemList list={method.steps?.preparation || []} title="preparation" methodId={method.id} />}
          {activeTab === 'executions' && <MethodStepItemList list={method.steps?.execution || []} title="execution" methodId={method.id} />}

          {activeTab === 'materials' && <MethodMaterialItemList list={method?.materials || []} title="material" methodId={method.id} />}
        </>
      )}
    </div>
  );
};
