import React from 'react';

import {LightBulb} from 'assets';
import {Card, RichTypography} from 'components';
import {upperFirst} from '../../../../services';
import {useLocale} from 'hooks';

interface TipsAndMaterialProps {
  method: ToolboxObject.MethodDetails;
}

export const Tips: React.FC<TipsAndMaterialProps> = ({method}) => {
  const locale = useLocale();
  const tips = method?.infos?.tip;

  if (tips) {
    return (
      <div className="mt-[90px]">
        <Card headerIcon={<LightBulb />} headerTitleKey="pages.library.method.tips" color={method.subcategory?.category.color}>
          <ul>
            {tips.map((info) => {
              const tipText = info.translation?.[locale] || info.description;
              return (
                <li key={info.id}>
                  <RichTypography text={upperFirst(tipText)} />
                </li>
              );
            })}
          </ul>
        </Card>
      </div>
    );
  }
  return null;
};
