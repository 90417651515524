import React from 'react';

import {ClipBoardCheck, Download, ShoppingBag} from 'assets';
import {Card, LocalizedText, RichTypography} from 'components';

import styles from './Resource.module.scss';
import {upperFirst} from '../../../../services';
import {useLocale} from 'hooks';

interface ResourcesProps {
  method: ToolboxObject.MethodDetails;
}

export const Resources: React.FC<ResourcesProps> = ({method}) => {
  const locale = useLocale();
  return method?.materials?.length ? (
    <div className={styles.resources}>
      <Card headerIcon={<ClipBoardCheck />} headerTitleKey="pages.library.method.material" color={method.subcategory?.category.color}>
        <ul>
          {method.materials.map((material) => {
            const materialName = material.translation?.[locale] || material.name;
            return (
              <li key={material.id}>
                <RichTypography text={upperFirst(materialName)} />
              </li>
            );
          })}
        </ul>
      </Card>
      <div className={styles.wrapper}>
        {method.documents?.map((doc) => {
          const {id: fileId, display_name} = doc.file[0];
          const {id: previewId, preview_link} = doc.preview[0] || {};
          const downloadLink = `${process.env.REACT_APP_TOOLBOX_HOST}/files/${fileId}/download`;
          return (
            <div className={styles.card} key={fileId}>
              {preview_link && (
                <div className={styles.imageWrapper}>
                  <img key={previewId} src={preview_link} className={styles.image} alt={doc.display_name} />
                </div>
              )}
              <div className={styles.cardInfo}>
                <p className={styles.text}>{display_name}</p>
                <div className={styles.cardActions}>
                  <a href={downloadLink} target="_blank" rel="noreferrer" className={styles.link}>
                    <Download /> <LocalizedText id="common.download" />
                  </a>
                  {doc.orderable && (
                    <button type="button" className={styles.link}>
                      {' '}
                      <a
                        href="https://mokojam.com/collections/printing"
                        className="inline-flex gap-1.5 items-center justify-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ShoppingBag />
                        <LocalizedText id="pages.library.method.orderPoster" />
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
