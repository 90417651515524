import {ColumnType} from 'antd/es/table';

export const columns: ColumnType<ToolboxObject.Translation>[] = [
  {
    title: 'Key',
    dataIndex: 'key',
    width: 150,
  },
  {
    title: 'English',
    dataIndex: 'en',
  },
  {
    title: 'German',
    dataIndex: 'de',
  },
  {
    title: 'French',
    dataIndex: 'fr',
  },
  {
    title: 'Italian',
    dataIndex: 'it',
  },
];
