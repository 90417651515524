import React, {useMemo, useState} from 'react';
import {Droppable} from '@hello-pangea/dnd';
import Fuse from 'fuse.js';
import classNames from 'classnames';
import {groupMethodsByCategory, sortMethodCategories} from '../../../services';
import {Collapsible, Loader, LocalizedText, MethodLibraryTile, SearchInput} from '../../../components';
import {DraggableTile} from './DraggableTile';
import {methodIdToDraggableId} from '../helpers';
import {NavTabs} from '../../LibraryPage/NavTabs';
import styles from '../WorkshopsPage.module.scss';
import {useIntl} from 'react-intl';

const tabs = {
  methods: {
    id: 'Methods',
    titleKey: 'pages.library.methodsTab',
  },
  favorites: {
    id: 'Favorites',
    titleKey: 'pages.library.favoritesTab',
  },
  others: {
    id: 'Others',
    titleKey: 'pages.library.othersTab',
  },
};

interface MethodLibraryProps {
  methods: ToolboxObject.Method[];
  isLoading: boolean;
}

const MethodLibraryComponent: React.FC<MethodLibraryProps> = ({methods, isLoading}) => {
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState(tabs.methods.id);
  const [query, setQuery] = useState('');

  const methodsByCategory = useMemo(() => {
    let filteredData: ToolboxObject.Method[] = methods;
    if (activeTab === tabs.favorites.id) {
      filteredData = filteredData.filter((method) => {
        return method.user_data?.is_favorite;
      });
    } else if (activeTab === tabs.others.id) {
      filteredData = filteredData.filter((method) => {
        return method.is_break;
      });
    } else {
      filteredData = filteredData.filter((method) => {
        return !method.is_break;
      });
    }
    return groupMethodsByCategory(
      query
        ? new Fuse(filteredData, {keys: ['title', 'name'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
            return result.item;
          })
        : filteredData,
      true
    );
  }, [methods, query, activeTab]);

  return (
    <div
      className={classNames(
        styles.noScrollbar,
        'flex flex-col gap-4 grow-[999] basis-0 bg-gray-50 p-6 sm:p-12 sm:pb-0 md:h-[calc(100vh-64px)] md:overflow-y-auto'
      )}
    >
      <div className="flex justify-between flex-wrap gap-4">
        <p className="text-2xl font-extrabold text-gray-900 pb-2.5">
          <LocalizedText id="pages.workshop.library" />
        </p>
        <SearchInput query={query} setQuery={setQuery} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full border-b border-b-black/60">
            <NavTabs tabs={[tabs.methods, tabs.favorites, tabs.others]} activeTab={activeTab} setActiveTab={setActiveTab} disableNavigate />
          </div>
          <div className={classNames(styles.noScrollbar, 'flex flex-col gap-4 pb-12 md:h-full md:overflow-y-auto pt-8')}>
            {Object.entries(methodsByCategory)
              .sort(sortMethodCategories)
              .map(([category, categoryMethods], index) => {
                // Collapsible component cannot be controlled
                // Random key is for react to unmount component each time
                const key = query ? crypto.randomUUID() : `${category}_${activeTab}`;
                const defaultOpen = query ? true : index === 0 || category === 'Other Activities';

                const categoryKey = category.split(' ').join('_').toLowerCase();
                const title = intl.formatMessage({id: `pages.library.category.${categoryKey}`, defaultMessage: category});
                return (
                  // Render full opened Collapsible components when query is not empty
                  <Collapsible title={title} key={key} defaultOpen={defaultOpen}>
                    <Droppable droppableId={category} isDropDisabled type="TILE">
                      {(provided) => {
                        return (
                          <div className="flex flex-col gap-4 mb-12 mt-6 pr-2" {...provided.droppableProps} ref={provided.innerRef}>
                            {categoryMethods.map((method: ToolboxObject.Method, draggableIndex: number) => {
                              const draggableId = methodIdToDraggableId(method.id, category);

                              return (
                                <DraggableTile key={draggableId} index={draggableIndex} draggableId={draggableId}>
                                  <MethodLibraryTile method={method} stateModal />
                                </DraggableTile>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </Collapsible>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export const MethodLibrary = React.memo(MethodLibraryComponent);
